import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between -mt-4 border-b pb-4" }
const _hoisted_4 = { class: "flex items-center text-lg font-semibold text-gray-900" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "text-base font-medium text-gray-900" }
const _hoisted_7 = {
  key: 0,
  class: "font-medium text-gray-900"
}
const _hoisted_8 = {
  key: 1,
  class: "text-sm font-medium capitalize text-gray-500"
}
const _hoisted_9 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_10 = { class: "inline-block font-medium text-gray-900" }
const _hoisted_11 = { class: "text-sm font-medium capitalize text-gray-500" }
const _hoisted_12 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverLink = _resolveComponent("PopoverLink")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.filtered ? _ctx.$t('platform.common.search-results') : _ctx.$t('platform.navigation.participants')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                icon: "search",
                "icon-position": "left",
                "icon-height": "16px",
                placeholder: _ctx.$t('platform.common.search'),
                size: "small",
                onKeyup: _ctx.updateSearchDebounced
              }, null, 8, ["modelValue", "placeholder", "onKeyup"]),
              _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
                to: "/participants/new",
                class: "ml-4",
                "left-icon": "add",
                type: "button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.participant.new')), 1)
                ]),
                _: 1
              })), [
                [_directive_allow, 'anonymous-participant:create']
              ])
            ])
          ]),
          _createVNode(_component_DataTable, {
            loading: _ctx.loading,
            columns: _ctx.columns,
            rows: _ctx.rows,
            sort: _ctx.sort,
            class: "w-full mt-6",
            onSort: _ctx.updateSort
          }, {
            "cell.participant": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_6, " ID: " + _toDisplayString(row.custom_identifier), 1)
            ]),
            "cell.date_of_birth": _withCtx(({ row }) => [
              (row.date_of_birth)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$d(new Date(row.date_of_birth), 'shortMonth')), 1))
                : _createCommentVNode("", true),
              (row.date_of_birth)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.getGender(row.sex)) + " (" + _toDisplayString(_ctx.age(row.date_of_birth)) + ") ", 1))
                : _createCommentVNode("", true)
            ]),
            "cell.latest_patient_visit": _withCtx(({ row, value }) => [
              value
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createTextVNode(_toDisplayString(row.latest_patient_visit_form_name) + " ", 1),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$d(new Date(value), 'shortMonth')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            "cell.actions": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_12, [
                _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
                  to: `/participants/${row.id}`,
                  "right-icon": "chevron-right",
                  color: "ghost"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.view')), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])), [
                  [_directive_allow, 'anonymous-participant:read']
                ]),
                _withDirectives((_openBlock(), _createBlock(_component_BasePopover, { position: "left" }, {
                  button: _withCtx(() => [
                    _createVNode(_component_SpecialityButton, { class: "ml-3" })
                  ]),
                  menu: _withCtx(() => [
                    _createVNode(_component_PopoverLink, {
                      to: '/participants/' + row.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.participant.view')), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024)), [
                  [_directive_allow, 'anonymous-participant:read']
                ])
              ])
            ]),
            _: 1
          }, 8, ["loading", "columns", "rows", "sort", "onSort"]),
          (_ctx.total && _ctx.total > _ctx.perPage)
            ? (_openBlock(), _createBlock(_component_BasePagination, {
                key: 0,
                "model-value": _ctx.page,
                "page-size": _ctx.perPage,
                length: _ctx.total,
                "sm-pages-limit": 5,
                "md-pages-limit": 8,
                "items-name": _ctx.$t('platform.navigation.participants').toLowerCase(),
                "onUpdate:modelValue": _ctx.changePage
              }, null, 8, ["model-value", "page-size", "length", "items-name", "onUpdate:modelValue"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}