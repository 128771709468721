
import { Vue, Options } from 'vue-class-component';
import DefaultLayout from '@/lib/layouts/DefaultLayout.vue';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import debounce from 'lodash-es/debounce';
import {
  BaseTextInput,
  ButtonLink,
  DataTable,
  SpecialityButton,
  PopoverLink,
  BasePopover,
  BasePagination
} from '@/lib/components';
import { Participant } from '@/custom/research/models/participant.model';
import { AnonymousParticipantService } from '@/custom/research/services/anonymous-participant.service';
import Calendar from 'dayjs/plugin/calendar';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { PaginatedResponse } from '@/models';
import { participantGenderOptions } from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(localizedFormat);

@Options({
  components: {
    BasePagination,
    PopoverLink,
    SpecialityButton,
    DataTable,
    ButtonLink,
    BaseTextInput,
    DefaultLayout,
    BasePopover
  }
})
export default class ParticipantListPage extends Vue {
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  loading = true;
  perPage = 0;
  total = 0;
  search = '';
  filtered = false;

  rows: Array<Participant> = [];

  request: CancelTokenSource | null = null;

  get participantService(): AnonymousParticipantService {
    return new AnonymousParticipantService(this.organisationId);
  }

  get columns() {
    return [
      {
        name: 'custom_identifier',
        label: this.$t('platform.participant.participant'),
        sortable: true
      },
      { name: 'date_of_birth', label: this.$t('platform.patient.born'), sortable: true },
      {
        name: 'latest_patient_visit',
        label: this.$t('platform.common.last-patient-visit'),
        sortable: true
      },
      { name: 'actions', label: '', align: 'right' }
    ];
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get sort() {
    return this.$route.query.sort || 'custom_identifier';
  }

  created() {
    this.progressStore.startProgress();
    dayjs.extend(Calendar);
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  mounted() {
    this.$watch('organisationId', async () => {
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      this.search = String(this.$route.query.search || '');
      await this.fetchParticipants();
    });
    this.search = String(this.$route.query.search || '');
    this.fetchParticipants();

    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchParticipants();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });
  }

  async fetchParticipants() {
    this.loading = true;

    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        page: this.page,
        sort: this.sort,
        ...(this.search ? { 'filter[search]': this.search } : {})
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.participantService.index(requestConfig)) as PaginatedResponse<Participant[]>;
      this.request = null;
      this.rows = response.data;

      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.filtered = this.search.length > 0;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.participant.fetch-error')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  age(dob: string): string {
    return dayjs(dob).isValid() ? `${dayjs().diff(dayjs(dob), 'year')}y` : '';
  }

  getGender(sex: string): string | TranslateResult {
    const gender = participantGenderOptions().find((option) => option.value === sex);
    return gender ? gender.label : sex;
  }
}
